import React from 'react'

// Components
import Clients from 'components/flex/Components/Clients'
import Optimize from 'components/flex/Components/Optimize'
import Sqaures from 'components/flex/Components/Squares'
import FAQ from 'components/flex/Components/FAQ'
import AllPartners from 'components/flex/Components/AllPartners'
import HorizontalSqaures from 'components/flex/Components/HorizontalSqaures'
import Integrations from 'components/flex/Components/Integrations'
import SearchEngine from 'components/flex/Components/SearchEngine'
import Intro from 'components/flex/Components/Intro'

interface ComponentsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

interface PostProps {
  [key: string]: any
}

const ComponentsShell: React.FC<ComponentsShellProps> = ({
  fields,
  location = {},
}) => {
  const types: PostProps = {
    clients: Clients,
    optimize: Optimize,
    sqaures: Sqaures,
    horizontalsquares: HorizontalSqaures,
    faq: FAQ,
    allpartners: AllPartners,
    integrations: Integrations,
    searchengine: SearchEngine,
    intro: Intro,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ComponentsShell
