/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface HorizontalSqauresProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding-top: 100%;
  position: relative;
  margin: 12px 0;
`

const ContentWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  @media screen and (max-width: 575px) {
    & h5 {
      font-size: 30px;
    }

    & p,
    a {
      font-size: 18px;
      line-height: 34px;
    }

    & a {
      padding-top: 10px;
    }
  }
`

const StyledLink = styled.a`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const Content = styled(ParseContent)`
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: 30.65px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.regular};
      line-height: ${({ theme }) => theme.font.size.regular};
    }
  }
`

const HorizontalSqaures: React.FC<HorizontalSqauresProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <ParseContent content={fields.title || ''} />
      <div className="row pt-2">
        {fields.hsqaures?.map((q, index) => (
          <div key={index} className="col-sm-6 col-lg-3">
            <Block>
              <ContentWrapper className="d-flex justify-content-center align-items-center">
                <div>
                  <Content content={q?.description || ''} />
                  <div className="d-flex justify-content-center">
                    <StyledLink target="__blank" href={q?.link?.url || '/'}>
                      {q?.link?.title}
                    </StyledLink>
                  </div>
                </div>
              </ContentWrapper>
            </Block>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default HorizontalSqaures
