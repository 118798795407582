import { useContext } from 'react'

import { ConnectionContext } from 'components/flex/Components/Integrations'

const useConnection = () => {
  const context = useContext(ConnectionContext)

  return context
}

export default useConnection
