import React from 'react'
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Images
import blueArrow from 'img/blue_arrow.svg'
import NavLink from 'components/shared/NavLink'

const Wrapper = styled.section`
  min-height: 40vh;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const Input = styled.input`
  z-index: 2;
  position: relative;
  padding: 20px 25px;
  border-radius: 24px;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.primary};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  @media (min-width: 576px) {
    width: 300px;
  }
`

const SearchResult = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  margin: 20px 0 20px 0;
  background-color: ${({ theme }) => theme.color.light};
`

const Type = styled.p`
  position: relative;
  font-weight: bold;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

const StyledButton = styled(Button)``

interface SearchEngineProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
  location?: any
  className?: string
}

const SearchEngine: React.FC<SearchEngineProps> = ({ fields }) => {
  const { posts, pages, cases } =
    useStaticQuery<GatsbyTypes.searchQueryQuery>(graphql`
      query searchQuery {
        posts: localSearchPosts {
          index
          store
        }
        pages: localSearchPages {
          index
          store
        }
        cases: localSearchCases {
          index
          store
        }
      }
    `)

  const [query, setQuery] = React.useState<string | null>('')

  const pageResults = useFlexSearch(query, pages?.index, pages?.store)

  const postResults = useFlexSearch(query, posts?.index, posts?.store)

  const caseResults = useFlexSearch(query, cases?.index, cases?.store)

  function handleSearchChange(e: any) {
    setQuery(e.target.value)
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    console.log(params)

    setQuery(params)
  }, [])

  return (
    <Wrapper className="container my-5">
      <div className="row mb-5 pb-lg-5 justify-content-center">
        <div className="col-lg-4">
          <Content content={fields.description || ''} />
          <div className="d-flex justify-content-center">
            <Input
              type="search"
              className=""
              value={query || ''}
              onChange={handleSearchChange}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {query !== null &&
          caseResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div className="d-flex justify-content-start">
                  <Type>{r.type}</Type>
                </div>
                <h3>{parse(r.title)}</h3>
                <div className="d-flex justify-content-end mt-auto">
                  <StyledButton className="secondary filled" to={r.uri}>
                    <img src={blueArrow} alt="" />
                  </StyledButton>
                </div>
              </SearchResult>
            </div>
          ))}
        {query !== null &&
          pageResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div className="d-flex justify-content-start">
                  <Type>{r.type}</Type>
                </div>
                <h3>{parse(r.title)}</h3>
                <div className="d-flex justify-content-end mt-auto">
                  <StyledButton className="secondary filled" to={r.uri}>
                    <img src={blueArrow} alt="" />
                  </StyledButton>
                </div>
              </SearchResult>
            </div>
          ))}
        {query !== null &&
          postResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div>
                  <div className="d-flex justify-content-start">
                    <Type>{r.type}</Type>
                  </div>
                  <h3>{parse(r.title)}</h3>
                </div>

                <div className="d-flex justify-content-end mt-auto">
                  <StyledButton className="secondary filled" to={r.uri}>
                    <img src={blueArrow} alt="" />
                  </StyledButton>
                </div>
              </SearchResult>
            </div>
          ))}
      </div>
      {query !== null &&
        query !== '' &&
        caseResults < 1 &&
        postResults < 1 &&
        pageResults < 1 && (
          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
            <h2>Could not find anything. Try something else or &nbsp;</h2>
            <Button className="mt-4 mt-sm-0 p-0">
              <NavLink to="/" className="p-0">
                return to homepage
              </NavLink>
            </Button>
          </div>
        )}
    </Wrapper>
  )
}

export default SearchEngine
