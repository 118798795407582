import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Plus from 'img/plus.inline.svg'
import Min from 'img/min.inline.svg'

// Third Party
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

interface FAQProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Question = styled.div`
  box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.color.grey};
  padding: 30px 40px;
  cursor: pointer;
  transition: all 0.3s ease;
`

const Answer = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  overflow: hidden;
`

const AnswerContent = styled.div`
  padding: 30px 40px;
`

const FAQ: React.FC<FAQProps> = ({ fields }) => {
  const [openIndex, setOpenIndex] = React.useState(-1)

  return (
    <section className="py-lg-5 py-3">
      <div className="container py-lg-5 py-3">
        <ParseContent content={fields.title || ''} />
        <div className="row pb-4 justify-content-center">
          <div className="col-lg-9">
            {fields.faqitems?.map((faqitem, index) => (
              <div className="my-3">
                <Question
                  onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
                  className="w-100"
                >
                  <div className="row justify-content-between">
                    <div className="col-lg-7">
                      <ParseContent content={faqitem?.question || ''} />
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end align-items-center">
                      {index !== openIndex ? <Plus /> : <Min />}
                    </div>
                  </div>
                </Question>
                <AnimatePresence>
                  {openIndex === index && (
                    <Answer
                      initial={{ height: 0 }}
                      animate={{ height: 'fit-content' }}
                      exit={{ height: 0 }}
                    >
                      <AnswerContent>
                        <ParseContent content={faqitem?.answer || ''} />
                      </AnswerContent>
                    </Answer>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
        <ParseContent content={fields.description || ''} />
      </div>
    </section>
  )
}

export default FAQ
