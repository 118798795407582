import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  position: relative;
  @media (min-width: 992px) {
    margin-top: -10rem;
  }
`

const IntroWrapper = styled.div`
  background-color: #f4f4f4;
  @media (min-width: 576px) {
    padding: 5rem;
    border-radius: 45px;
  }
  @media (max-width: 575px) {
    padding: 1rem;
  }
`

interface IntroProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Intro: React.FC<IntroProps> = ({ fields }) => {
  const { intro } = fields

  return (
    <Section>
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <IntroWrapper>
              <div className="row">
                <div className="col-xl-6">
                  <Plaatjie image={intro?.image} alt="" className="h-100" />
                </div>
                <div className="col-xl-6">
                  <ParseContent
                    content={intro?.description || ''}
                    className="p-sm-4 mt-4"
                  />
                </div>
              </div>
            </IntroWrapper>
          </div>
        </div>

        <ParseContent content={fields.description || ''} />
      </div>
    </Section>
  )
}

export default Intro
