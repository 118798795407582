import React from 'react'
import { Link } from 'gatsby'

// Images
import FlowizeBlocks from 'img/big_flowize_blocks.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

interface OptimizeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  margin: 0 0 100px 0;
  position: relative;
  @media (min-width: 992px) {
    height: 430px;
  }

  @media screen and (max-width: 991px) {
    margin: 0 0 30px 0;
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 1200px) {
    & h2 {
      font-size: 75px;
      padding-bottom: 0;
      line-height: 50px;
    }
  }
  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    & h2 {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 12rem;
    padding-right: 40px;

    & h2 {
      line-height: 50px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 9rem;

    & h2 {
      font-size: 40px;
    }
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
  pointer-events: none;

  @media (min-width: 992px) {
    & svg {
      height: 100%;
    }
  }
`

const AnimationWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 30px 0;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-vertical {
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    padding-top: 10px;

    & a {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  &::before {
    content: '';
    height: 320px;
    width: 1px;
    background-color: ${({ theme }) => theme.color.primary};
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 768px) {
      height: 170px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`

const StyledLink = styled(Link)<{ active: boolean }>`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.3;

          @media screen and (max-width: 991px) {
            opacity: 0.1;
          }
        `}

  @media (min-width: 1200px) {
    font-size: 75px;
    line-height: 80px;
  }

  @media (max-width: 1199px) {
    font-size: 40px;
    line-height: 40px;
  }
`

const StyledButton = styled(Button)`
  /* position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; */
  position: relative;
  margin-top: -1.5rem;

  @media screen and (max-width: 991px) {
    margin-top: 0;
    margin-bottom: -1rem;
  }
`

const Col = styled.div`
  @media (min-width: 992px) {
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    height: 200px;
  }
`

const OtherCol = styled.div`
  @media screen and (max-width: 991px) {
    height: 20px !important;
    background-color: ${({ theme }) => theme.color.grey};
    z-index: 2;
  }
  @media screen and (max-width: 375px) {
    & h2 {
      font-size: 30px !important;
      line-height: 32px;
    }
  }
`

const Optimize: React.FC<OptimizeProps> = ({ fields }) => (
  <section>
    <Wrapper>
      <SvgWrapper>
        <FlowizeBlocks />
      </SvgWrapper>
      <div className="container h-100">
        <div className="row h-100">
          <OtherCol className="col-sm-6 h-100 pe-md-5 d-flex align-items-center justify-content-lg-end">
            <Content content={fields.title || ''} />
          </OtherCol>
          <Col className="col-sm-6 ps-lg-5 d-flex align-items-center">
            <AnimationWrapper>
              <Swiper
                slidesPerView={3}
                direction="vertical"
                centeredSlides
                speed={1500}
                autoplay
                breakpoints={{
                  0: {
                    spaceBetween: 10,
                  },
                  1024: {
                    spaceBetween: 20,
                  },
                  1200: {
                    spaceBetween: 50,
                  },
                }}
              >
                {fields.links?.map((link, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={index}>
                    {({ isActive }) => (
                      <StyledLink active={isActive} to={link?.link?.url || '/'}>
                        {link?.link?.title}
                      </StyledLink>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </AnimationWrapper>
          </Col>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <StyledButton className="secondary filled" to={fields.link?.url || '/'}>
          {fields.link?.title}
        </StyledButton>
      </div>
    </Wrapper>
  </section>
)

export default Optimize
