import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

// Elements
import { ButtonCSS } from 'components/elements/Buttons/Button'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Images
import chain from 'img/chain.svg'
import useConnection from 'hooks/useConnection'
import useMedia from 'use-media'
import Close from 'img/close.inline.svg'

const StyledChain = styled.img`
  width: 50px;
  height: 50px;
  transform: rotate(0deg);
`

const ChainWrapper = styled.div``

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1rem;

    & .form-duuf-error {
      padding-left: 20px;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-radius: 25.5px;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    padding: 0 20px;
    font-size: 16px;
  }

  & .form-duuf-textarea {
    height: 143px;
    padding-top: 10px;
  }

  & .form-duuf-file-button {
    ${ButtonCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding: 0 0 5px 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 14px;
  }

  & .form-duuf-submit {
    ${ButtonCSS};

    border: none;

    a,
    button {
      background-color: ${({ theme }) => theme.color.secondary};
      border-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      border-radius: 25.5px;
      height: 100%;
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      font-size: 14px;
      padding: 15px 80px;
    }

    svg {
      color: ${({ theme }) => theme.color.primary};
    }

    &-disabled {
      opacity: 1;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
    font-size: 25px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const CloseWrapper = styled.button`
  position: absolute;
  right: 19px;
  top: 19px;

  & svg {
    width: 25px;
    height: 25px;
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
`

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
`

interface FormMarketplaceProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  isOpen: boolean
  requestClose: Function
}

const Title = styled.h2`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
`

const FormMarketplace: React.FC<FormMarketplaceProps> = ({
  isOpen,
  requestClose,
}) => {
  const isDesktop = useMedia({ minWidth: 992 })
  const isMobile = useMedia({ maxWidth: 575 })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      height: '80%',
      width: isDesktop ? '60%' : '95%',
      maxWidth: 1140,
      overflowY: 'scroll',
      overflowX: 'hidden',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      borderRadius: '20px',
      padding: 0,
    },
  }

  const connection = useConnection()

  return (
    <Modal isOpen={isOpen} onRequestClose={requestClose} style={customStyles}>
      <Background className="position-relative mb-5">
        <CloseWrapper
          className="position-absolute"
          onClick={() => requestClose()}
        >
          <Close />
        </CloseWrapper>

        <div className="px-5 py-4 d-flex flex-column flex-sm-row align-items-center">
          <ChainWrapper className="me-3">
            <StyledChain src={chain} />
          </ChainWrapper>
          <Title className="pb-0 mb-0">
            Send us your requested connection or question, and we will get in
            touch with you!
          </Title>
        </div>
      </Background>
      <StyledFormDuuf
        id={3}
        generate={false}
        defaultFields={{
          input_2: connection.firstConnection,
          input_3: connection.secondConnection,
        }}
      >
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row">
              <div className="col-sm-6">
                {isMobile ? (
                  <FormDuufGroup showIds={[2, 3, 4, 7]} hideIds={[5, 6, 8]} />
                ) : (
                  <FormDuufGroup showIds={[2, 4, 7]} hideIds={[3, 5, 6, 8]} />
                )}
              </div>
              <div className="col-sm-6">
                {isMobile ? (
                  <FormDuufGroup
                    showIds={[3, 5, 6]}
                    hideIds={[2, 3, 4, 7, 8]}
                  />
                ) : (
                  <FormDuufGroup showIds={[3, 5, 6]} hideIds={[2, 4, 7, 8]} />
                )}
              </div>
              <div className="col-12">
                <FormDuufGroup showIds={[8]} hideIds={[2, 4, 7, 3, 5, 6]} />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <FormDuufSubmit />
            </div>
          </div>
        </div>
      </StyledFormDuuf>
    </Modal>
  )
}

export default FormMarketplace
