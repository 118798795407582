/* eslint-disable react/no-array-index-key */
import React, { createContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import parse, { domToReact } from 'html-react-parser'

// Images
import chain from 'img/chain.svg'
import blueArrow from 'img/blue_arrow.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import { ButtonCSS } from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'
import FormMarketplace from '../Form/FormMarketplace'

export const ConnectionContext = createContext({
  firstConnection: '',
  secondConnection: '',
})

interface IntegrationsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const SearchWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const StyledChain = styled.img`
  @media (max-width: 767px) {
    margin: 10px auto;
    width: 60px;
    height: 60px;
  }
`

const Input = styled.input<{ selected: boolean }>`
  outline: none;
  border-radius: 25.5px;
  padding: 0 20px;
  min-height: 50px;
  transition: all 0.3s ease;

  ${({ selected, theme }) =>
    selected
      ? css`
          border: 2px solid ${theme.color.secondary};
        `
      : css`
          border: 2px solid transparent;
        `}

  @media (min-width: 992px) {
    width: 330px;
  }
`

const StyledButton = styled.button`
  ${ButtonCSS}
  padding: 12px !important;
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
`

const IntegrationsWrapper = styled.div``

const SemiContainer = styled.div`
  width: 1420px;
  overflow-x: hidden;
`

const Integration = styled.div<{ selected: boolean }>`
  background-color: #c4c4c4;
  border-radius: 20px;
  border: 5px solid #c4c4c4;
  & h2 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.light};
    margin-top: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    border: 5px solid ${({ theme }) => theme.color.secondary};
  }

  ${(props) =>
    props.selected &&
    css`
      border: 5px solid ${({ theme }) => theme.color.secondary};
    `}

  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 767px) {
    width: 110px;
    height: 110px;
  }

  @media (max-width: 575px) {
    width: 105px;
    height: 105px;

    & h2 {
      font-size: 12px;
      color: ${({ theme }) => theme.color.light};
      margin-top: 0.5rem;
    }
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 575px) {
    width: 20px;
    height: 25px;
  }
`

const Content = styled(ParseContent)`
  & h3,
  & h4,
  & h5,
  & h6 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const Integrations: React.FC<IntegrationsProps> = ({ fields }) => {
  const { integrations, allIntegrations } =
    useStaticQuery<GatsbyTypes.integrationQueryQuery>(graphql`
      query integrationQuery {
        integrations: localSearchIntegrations {
          index
          store
        }

        allIntegrations: allWpIntegration {
          nodes {
            title
            integrationFlex {
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `)

  const [query, setQuery] = useState<string>('')
  const [selectionSelected, setSelectionSelected] = React.useState<
    0 | 1 | null
  >(null)
  const [firstSelection, setFirstSelection] = useState('')
  const [secondSelection, setSecondSelection] = useState('')
  const [modal, setModal] = useState(false)

  const integrationResults = useFlexSearch(
    query,
    integrations?.index,
    integrations?.store
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query') || ''

    setQuery(params)
  }, [])

  useEffect(() => {
    if (selectionSelected === null) {
      if (firstSelection !== '' && secondSelection !== '') {
        if (firstSelection === secondSelection) {
          setFirstSelection('')
          setSecondSelection('')
        }
      }
      return
    }

    setQuery(selectionSelected === 0 ? firstSelection : secondSelection)
  }, [firstSelection, secondSelection])

  const handleIntegrationClick = (title: string) => {
    if (
      [firstSelection.toLowerCase(), secondSelection.toLowerCase()].indexOf(
        title.toLowerCase()
      ) !== -1
    ) {
      if (firstSelection.toLowerCase() === title.toLowerCase()) {
        setFirstSelection('')
      } else if (secondSelection.toLowerCase() === title.toLowerCase()) {
        setSecondSelection('')
      }
    } else if (selectionSelected === null) {
      if (firstSelection === '' && secondSelection === '') {
        setFirstSelection(title)
        setSelectionSelected(null)
      } else if (firstSelection !== '' && secondSelection === '') {
        setSecondSelection(title)
        setSelectionSelected(null)
      } else if (firstSelection === '' && secondSelection !== '') {
        setFirstSelection(title)
        setSelectionSelected(null)
      } else if (firstSelection !== '' && secondSelection !== '') {
        setFirstSelection(title)
        setSelectionSelected(null)
      }
    } else if (selectionSelected === 0) {
      setFirstSelection(title)
      setSelectionSelected(null)
    } else if (selectionSelected === 1) {
      setSecondSelection(title)
      setSelectionSelected(null)
    }
  }

  integrationResults.forEach((item: any, index: number) => {
    if (item.type === 'Integratie') {
      const integration: any = integrationResults.splice(index, 1)
      integrationResults.unshift(integration[0])
    }
  })

  return (
    <section className="py-lg-5 py-3">
      <div className="container pb-3 pt-xl-5 pt-3">
        <ParseContent content={fields.title || ''} />
      </div>
      <SearchWrapper>
        <div className="container py-5">
          <Content content={fields.description || ''} />
          <div className="row pt-4 justify-content-center">
            <div className="col-lg-10">
              <div className="d-flex flex-column flex-md-row justify-content-evenly">
                <Input
                  selected={selectionSelected === 0}
                  placeholder="Find system"
                  onChange={(event) => {
                    setFirstSelection(event.target.value)
                  }}
                  value={firstSelection}
                  onClick={() => {
                    setSelectionSelected(0)
                  }}
                  // onBlur={() => {
                  //   setSelectionSelected(null)
                  // }}
                />
                <StyledChain alt="" src={chain} />
                <Input
                  selected={selectionSelected === 1}
                  placeholder="Find system"
                  onChange={(event) => {
                    setSecondSelection(event.target.value)
                  }}
                  value={secondSelection}
                  onClick={() => {
                    setSelectionSelected(1)
                  }}
                  // onBlur={() => {
                  //   setSelectionSelected(null)
                  // }}
                />
                <StyledButton
                  className="secondary filled"
                  onClick={() => setModal(true)}
                >
                  <img src={blueArrow} alt="" />
                </StyledButton>
                {modal && (
                  <ConnectionContext.Provider
                    value={{
                      firstConnection: firstSelection,
                      secondConnection: secondSelection,
                    }}
                  >
                    <FormMarketplace
                      isOpen={modal}
                      requestClose={() => setModal(false)}
                      fields={fields}
                    />
                  </ConnectionContext.Provider>
                )}
              </div>
            </div>
          </div>
        </div>
      </SearchWrapper>
      <IntegrationsWrapper className="py-5 d-flex justify-content-center">
        <SemiContainer>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="row">
                  {selectionSelected !== null && query !== '' && (
                    <>
                      {integrationResults.length > 0 &&
                        integrationResults.map((i: any, index: number) => (
                          <>
                            {index < 48 && (
                              <>
                                <div
                                  className="col-4 col-lg-3 col-xl-2 py-sm-2 py-1 my-1 d-flex justify-content-center h-100"
                                  key={i.id}
                                >
                                  <Integration
                                    key={i.title}
                                    role="button"
                                    className="d-flex flex-column justify-content-between h-100 align-items-center"
                                    onClick={() =>
                                      handleIntegrationClick(i.title)
                                    }
                                    selected={
                                      [
                                        firstSelection.toLowerCase(),
                                        secondSelection.toLowerCase(),
                                      ].indexOf(i.title.toLowerCase()) !== -1
                                    }
                                  >
                                    <div className="d-flex flex-column justify-content-center h-100 align-items-center my-lg-4 my-2">
                                      <Icon
                                        image={
                                          i.image
                                            ? i.image
                                            : allIntegrations.nodes.filter(
                                                (i) => i.title === 'Custom'
                                              )[0].integrationFlex.image
                                        }
                                        alt=""
                                      />

                                      <h2
                                        className="text-center"
                                        dangerouslySetInnerHTML={{
                                          __html: i.title,
                                        }}
                                        style={{ fontSize: 20 }}
                                      />
                                    </div>
                                  </Integration>
                                </div>
                              </>
                            )}
                          </>
                        ))}

                      {integrationResults.length < 1 && (
                        <div>
                          <h2>
                            Didn&apos;t find what you are looking for? Our
                            Managed Service Team is on hand at any time to
                            support your projects.
                          </h2>
                        </div>
                      )}
                    </>
                  )}
                  {(selectionSelected === null || query === '') && (
                    <>
                      {allIntegrations.nodes.map((i: any) => (
                        <div
                          className="col-4 col-lg-3 col-xl-2 py-sm-2 py-1 my-1 d-flex justify-content-center"
                          key={i.id}
                        >
                          <Integration
                            key={i.title}
                            role="button"
                            className="d-flex flex-column justify-content-center align-items-center"
                            onClick={() => handleIntegrationClick(i.title)}
                            selected={
                              [
                                firstSelection.toLowerCase(),
                                secondSelection.toLowerCase(),
                              ].indexOf(i.title.toLowerCase()) !== -1
                            }
                          >
                            <div className="d-flex flex-column justify-content-between align-items-center my-lg-4 my-2">
                              <Icon
                                image={i?.integrationFlex?.image}
                                alt=""
                                className="mt-3"
                              />
                              <h2
                                className="text-center"
                                dangerouslySetInnerHTML={{ __html: i.title }}
                                style={{ fontSize: 18 }}
                              />
                            </div>
                          </Integration>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SemiContainer>
      </IntegrationsWrapper>
    </section>
  )
}

export default Integrations
