/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import Button from 'components/elements/Buttons/Button'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface SqauresProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const GridContainer = styled.div`
  /* display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-gap: 20px; */
`

const StyledSqaure = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  position: relative;
  /* padding: 90px 90px 120px 90px; */
`

const IconContainer = styled.div`
  & img {
    width: 90px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 30px;
  right: 15px;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const Sqaures: React.FC<SqauresProps> = ({ fields }) => (
  <section id={fields.webid}>
    <div className="container py-lg-5 py-3">
      <GridContainer className="row mb-lg-5">
        {fields.sqaures?.map((sqaure, index) => (
          <div key={index} className="col-sm-6 mb-4">
            <StyledSqaure className="p-3 p-md-4 p-lg-5 h-100">
              <div className="pb-5">
                <div className="pb-5">
                  <IconContainer className="pb-5 d-flex justify-content-center">
                    <img src={sqaure?.icon?.localFile?.publicURL} alt="" />
                  </IconContainer>
                  <Content content={sqaure?.description || ''} />
                  <StyledButton
                    className="primary transparent hover-secondary"
                    arrow
                    to={sqaure?.link?.url || '/'}
                  >
                    {sqaure?.link?.title}
                  </StyledButton>
                </div>
              </div>
            </StyledSqaure>
          </div>
        ))}
      </GridContainer>
    </div>
  </section>
)

export default Sqaures
