/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
import Button from 'components/elements/Buttons/Button'

SwiperCore.use([Autoplay])

interface ClientsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledSwiper = styled(Swiper)`
  & .swiper-wrapper {
    align-items: center;
  }

  & .swiper-slide {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 75px;
      width: 1px;
      background-color: ${({ theme }) => theme.color.secondary};
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      @media (min-width: 576px) {
        right: -25px;
      }
      @media (max-width: 575px) {
        right: -10px;
      }
    }

    @media (min-width: 576px) {
      padding: 30px;
    }
    @media (max-width: 575px) {
      padding: 10px;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  max-height: 55px;

  & img {
    object-fit: contain !important;
  }
`

const Content = styled(ParseContent)`
  padding-bottom: 10px;

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.large};
    line-height: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: ${({ theme }) => theme.font.size.medium};
  }
`

const Clients: React.FC<ClientsProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="pb-lg-3">
        <Content content={fields.title || ''} />
      </div>
      <StyledSwiper
        breakpoints={{
          991: {
            spaceBetween: 50,
            slidesPerView: 4,
          },
        }}
        loop={(fields.clients?.length || 0) >= 5}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        slidesPerView={3}
      >
        {fields.clients?.map((client, index) => (
          <SwiperSlide key={index}>
            {client?.link ? (
              <Link to={client.link.url || ''}>
                <StyledPlaatjie image={client?.image} alt="" />
              </Link>
            ) : (
              <StyledPlaatjie image={client?.image} alt="" />
            )}
          </SwiperSlide>
        ))}
      </StyledSwiper>
      {fields.link && (
        <div className="d-flex justify-content-center py-5">
          <Button className="secondary filled" to={fields.link.url || ''}>
            {fields.link.title}
          </Button>
        </div>
      )}
    </div>
  </section>
)

export default Clients
