/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

interface AllPartnersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ImgWrapper = styled.div`
  flex: 0 1 calc(20% - 8px);
  align-items: center;
  display: flex;
  position: relative;
  filter: grayscale(100%);

  &:hover {
    opacity: 0.8;
  }

  & .gatsby-image-wrapper {
    max-height: 60px;
    max-width: 180px;
  }

  & img {
    object-fit: contain !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: -23px;
    left: 0;
    background-color: ${({ theme }) => theme.color.secondary};
    height: 46px;
    width: 2px;
    @media (max-width: 575px) {
      height: 23px;
      top: -11.5px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -23px;
    background-color: ${({ theme }) => theme.color.secondary};
    height: 2px;
    width: 46px;
    @media (max-width: 575px) {
      width: 23px;
      left: -11.5px;
    }
  }

  @media screen and (max-width: 575px) {
    flex: 0 1 calc(33% - 8px);
    padding: 20px 10px;

    &:nth-child(1),
    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(7),
    :nth-child(10),
    :nth-child(13),
    :nth-child(16),
    :nth-child(19) {
      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }
  }

  @media (min-width: 576px) {
    padding: 50px 30px;
    &:nth-child(1),
    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5),
    :nth-child(6),
    :nth-child(11),
    :nth-child(16),
    :nth-child(21) {
      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }
  }
`

const AllPartners: React.FC<AllPartnersProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 pt-3">
      <div className="row justify-content-center">
        <Swiper
          slidesPerView={5}
          speed={1500}
          autoplay
          breakpoints={{
            0: {
              spaceBetween: 10,
              slidesPerView: 3,
            },
            768: {
              spaceBetween: 50,
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 5,
            },
          }}
        >
          {fields.partners?.map((partner, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={index}>
              <ImgWrapper key={index}>
                <Link to={partner?.link?.url || ''}>
                  <Plaatjie image={partner?.image} alt="" />
                </Link>
              </ImgWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  </section>
)

export default AllPartners
